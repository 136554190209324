/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import services from '../../OIFendImages/images/services.svg';
import servicesImg from '../../Images/service-new.svg';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Anchor } from 'antd';

const { Link } = Anchor;

function Gallerry(props) {
  const { handleChange, dispatch } = props;
  const history = useHistory();

  const [showMore, setShowMore] = useState(true)
  const [showCount, setShowCount] = useState(8)

  console.log("history----", history);

  const ref = useRef(null);
  const serviceRef = useRef(null);
  useEffect(() => {
    ref.current.focus();
  }, [window.location.pathname]);

  useEffect(() => {
    handleChange(false)
    ref.current.focus();

    dispatch({
      type: "add", payload: {
        name: "ABC ...",
        locationL: "Mohali ...."
      }
    })
  }, []);

  const serviceData = [
    "Offices/Clerical",
    "Warehouse Trades",
    "Managers/Supervisor",
    "Team Leaders",
    "Clerical/Admin Staff",
    "Call Center Agents",
    "Receptionists",
    "Data Entry",
    "Purchasers General Labourers",
    "Assemblers/Sorters",
    "Machine Operators",
    "Forklift Operators",
    "Pickers/Packers",
    "Lead Hands/Team Leaders",
    "Janitors",
    "Shippers/Receivers Electricians",
    "Painter/Finishers",
    "Painters",
    "Tile Setters"
  ]

  const onChange = (link) => {
    console.log('Anchor:OnChange', link);
  };

  return (
    <div ref={ref} >
      <img ref={serviceRef} className="h200" src={services} alt="services" />

      <div className="mt-8 mb-14" id="servicerTop" >
        <div className='flex justify-center text-center mb-4 text-4xl font-bold'>
          <span className='border-b-2 primaryColor border-indigo-400'>
            What we do
          </span>
        </div>
        {/* component */}
        <div className="pt-6">
          <div className="container m-auto px-6 text-gray-500 md:px-12 xl:px-0">
            <div className='flex flex-wrap justify-center'>
              {serviceData.slice(0, showCount).map((service, index) => {
                return <div className='shadow-lg px-6 py-4 my-2 mx-2 border border-gray-100' key={index} >
                  <div className='text-black text-lg text-eclipses w-48'>{service}</div>
                </div>
              }
              )}
            </div>
            <Anchor affix={false} onChange={onChange}>
            {!showMore &&
              <Link href="#servicerTop" title={
                <button className='px-6 py-2 text-sm lg:text-lg mt-8 text-black bgPrimaryColor rounded-full hover:shadow-lg' onClick={() => {
                  setShowMore(!showMore);
                  setShowCount(8);
                  serviceRef.current.scrollIntoView({
                    behavior: "smooth"
                  });
                }}
                style={{ color: "white" }}
                >
                  Show Less
                </button>
              } />
            }
            {showMore &&
              <Link href="#servicerTop" title={
                <button className='px-6 py-2 text-sm lg:text-lg mt-8 text-black bgPrimaryColor rounded-full hover:shadow-lg' onClick={() => {
                  setShowMore(!showMore);
                  setShowCount(serviceData.length);
                  serviceRef.current.scrollIntoView();
                }}
                style={{ color: "white" }}
                >
                  Show More
                </button>
              } />
            }
            </Anchor>

            <div className='mt-8 py-8'>
              <div className='flex flex-wrap'>
                <div className='w-full lg:w-1/2 justify-center'>
                  <img src={servicesImg} style={{ height: "80%", paddingTop: "39px", width: "100%"}} alt="..." />
                </div>
                <div className='w-full lg:w-1/2'>
                    <p className='text-sm lg:text-lg text-left'>MetaForce Careers Employment Solutions prides itself in sourcing and fulfilling staffing service(s) in the most cost-efficient, reliable and experienced manner. Our designated recruiters are specially trained to screen each potential before the assignment is contracted. By offering our services to you, we assure you that we will take care of all the required government deductions as well as helping you save your precious time, effort and cost which incurs, if you hire directly. On behalf of our MetaForce Careers team, I look forward to establishing a long-lasting cordial and professional relationship with your company. I would like to set up a meeting in person or over the phone to discuss our very competitive rates, as well as what we can offer you to minimize your hiring cost. Please advise when we can set up a meeting to discuss further.</p>
                </div>
              </div>
              <div>
                <button className='px-6 py-2 text-sm lg:text-lg mt-8 text-black bgPrimaryColor rounded-full'>
                  <button to="contact" onClick={()=> history.push('/contact')} style={{ color: "white" }}>Contact Us</button>
                </button>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}
export default connect('')(Gallerry);
