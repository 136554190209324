/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
// import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import soonImg from '../../OIFendImages/images/careers/png/careers_banner.png'
import careersDataImage from '../../OIFendImages/images/careers/svg/careers - vector.svg';

function Careers(props) {
   const ref = useRef();
   const { handleChange } = props;
   console.log("carrer page", props);
    useEffect(() => {
        handleChange(false)
    }, []);

    return (
        <div ref={ref}>
         
            <img className="h200" src={soonImg} alt="..." />

          <div className="z-50 flex container mx-auto flex-col md:flex-row-reverse" > 
              <div className="w-full md:w-6/12 px-4 mr-auto ml-auto" >
                <div className="pt-0 md:pt-24">
                  <p className=" text-lg leading-relaxed text-black text-left border-2 border-white rounded-full pb-2 py-1">
                    We value our employees’ efforts, talent, creativity and that makes us different, working with MetaForce is self-growth, respect, learning, and motivation. Most importantly we want our employees to be well deserved.
                  </p>
                  <div className="text-center md:text-left">
                    <span className="mt-4 bg-blue-600 text-lg text-white border-2 border-white rounded-full px-4 py-2">
                       <Link to="/contact" style={{color: 'white'}}>
                          Join Our Team?
                        </Link>
                    </span>
                   
                  </div>
                </div>
              </div>
              
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-24 mb-16 md:my-0">
                <img alt="" className="max-w-full rounded-lg mt-20" src={careersDataImage} />
              </div>
            </div>
        
        </div>
    )
}
const mapStateToProps =(state)=>{
  return {
    data: state.data
  }
}

export default connect(mapStateToProps)(Careers);
