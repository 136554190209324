const initState ={
    data : [
        {
            "id": 1,
            "name": "Maheshvar Pandey",
            "location":"Mohali"
        },
        {
            "id": 2,
            "name": "Kamal",
            "location":"Ludhiana"
        }
    ]
}

const rootReducer = (state = initState, action) => {
    console.log("reducer", action);
    state = action.payload
    return state;
}

export default rootReducer;