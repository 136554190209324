import React, { useEffect, useRef } from 'react';
import HomePage from '../HomePage';
import Footer from '../Footer';
// import { Helmet } from 'react-helmet';

function Home(props) {
    const ref = useRef();
    const { handleChange } = props;
    useEffect(() => {
        ref.current.focus();
        handleChange(false)
    }, []);

    return (
        <div ref={ref}>
            {/* <Helmet>
                <meta charSet="utf-8" />
                <title>Oifend | Home</title>
            </Helmet> */}
            <HomePage /> 
            <Footer />
        </div>
    )
}

export default Home
