import React from 'react';
import img1 from '../../OIFendImages/images/1.jpg';
import img2 from '../../OIFendImages/images/2.jpg';
import img3 from '../../OIFendImages/images/3.jpg';
import img4 from '../../OIFendImages/images/4.jpg';
import img5 from '../../OIFendImages/images/5.jpg';
import img6 from '../../OIFendImages/images/6.jpg';    

const ImagesGallery = () => {
  return (
    <div>
          <section className="overflow-hidden text-gray-700 px-4 lg:px-0">
              <div className="container py-2 mx-auto lg:pt-6">
                  <div className="flex flex-wrap -m-1 md:-m-2">
                      <div className="flex flex-wrap w-1/2">
                          <div className="w-1/2 p-1 md:p-2">
                              <img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg" src={img1} />
                          </div>
                          <div className="w-1/2 p-1 md:p-2">
                              <img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg" src={img2} />
                          </div>
                          <div className="w-full p-1 md:p-2">
                              <img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg" src={img3} />
                          </div>
                      </div>
                      <div className="flex flex-wrap w-1/2">
                          <div className="w-full p-1 md:p-2">
                              <img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg" src={img4} />
                          </div>
                          <div className="w-1/2 p-1 md:p-2">
                              <img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg" src={img5} />
                          </div>
                          <div className="w-1/2 p-1 md:p-2">
                              <img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg" src={img6} />
                          </div>
                      </div>
                  </div>
              </div>
          </section>

    </div>
  )
}

export default ImagesGallery