/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';

import { Form, notification, Input, Button, Checkbox, onFinish, Row, Col, DatePicker, Select, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import SignatureCanvas from 'react-signature-canvas';
import generatePdf from './form';


import axios from 'axios';
const { Option } = Select;


function BackgroundCheck(props) {
  const ref = useRef();
  const formRef = useRef(null); // Reference to the form

  const signatureRef = useRef(null);
  const [signature, setSignature] = useState(null);

  const clearSignature = () => {
    signatureRef.current.clear();
    setSignature(null);
  };

  const saveSignature = () => {
    if (signatureRef.current.isEmpty()) {
      message.error('Please provide a signature');
    } else {

      setSignature(signatureRef.current.toDataURL());
    }
  };


  // const generateSignatureImage = (name) => {
  //   const canvas = document.createElement('canvas');
  //   const context = canvas.getContext('2d');
  //   canvas.width = 300;
  //   canvas.height = 100;
  
  //   context.fillStyle = 'white';
  //   context.fillRect(0, 0, canvas.width, canvas.height);
  
  //   context.font = 'italic 40px Brush Script MT, cursive';
  //   context.fillStyle = 'black';
  //   context.textAlign = 'center';
  //   context.textBaseline = 'middle';
  //   context.fillText(name, canvas.width / 2, canvas.height / 2);
  
  //   return canvas.toDataURL('image/png');
  // };

  const requied_field = true
  const [form] = Form.useForm();

  const [addressFields, setAddressFields] = useState([{ key: 0 }]);  
  const [offenceFields, setOffenceFields] = useState([{ key: 0 }]);  

  
  const addAddressField = () => {
    setAddressFields([...addressFields, { key: addressFields.length }]);
  };

  const removeAddressField = (key) => {
    setAddressFields(addressFields.filter((field) => field.key !== key));
  };

  const addOffenceField = () => {
    setOffenceFields([...offenceFields, { key: offenceFields.length }]);
  };

  const removeOffenceField = (key) => {
    setOffenceFields(offenceFields.filter((field) => field.key !== key));
  };


  const onFinish = (values) => {
        // Trigger PDF generation
        generatePdf(values, signature);
  };

  const uploadProps = {
    beforeUpload: (file) => {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        message.error(`${file.name} is not a valid image file`);
      }
      return isImage || Upload.LIST_IGNORE;
    },
    maxCount: 1,
  };



  return (
    <div ref={ref}>
      <div>
        <div className='px-8 py-8'>
          {/* component */}
          <div>
          <div  className="font-bold text-xl mb-10 px-0">Criminal Record Verification Informed Consent Form</div>
          <Form
              form={form}
              name="background-check"
              layout="vertical"
              onFinish={onFinish}
              style={{ maxWidth: 800, margin: '0 auto' }}
            >

            <div  className="font-bold text-lg mb-5 text-left">Personal Information:</div>
              {/* Personal Information */}
              <Row gutter={16}>
                <Col span={12} >
                  <Form.Item
                    label="Surname (Last Name)"
                    name="surname"
                    rules={[{ required: requied_field, message: 'Please enter your Surname (Last Name)!' }]}
                  >
                    <Input placeholder="surname" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Given Name(s)"
                    name="givenName"
                    rules={[{ required: requied_field, message: 'Please enter your Given name!' }]}
                  >
                    <Input placeholder="Given Name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Surname (Last Name) at birth"
                    name="surnameBirth"
                    rules={[{ required: requied_field, message: 'Please enter your Surname (Last Name) at birth!' }]}
                  >
                    <Input placeholder="surname at birth" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Former Name(s)"
                    name="formerName"
                    rules={[{ required: requied_field, message: 'Please enter your Former name!' }]}
                  >
                    <Input placeholder="Former Name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: requied_field, message: 'Please enter your email!' },
                      { type: 'email', message: 'The input is not a valid email!' },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Phone Number"
                    name="phone"
                    rules={[{ required: requied_field, message: 'Please enter your phone number!' }]}
                  >
                    <Input placeholder="Phone Number" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Place of Birth"
                    name="placeOfBirth"
                    rules={[{ required: requied_field, message: 'Please enter your place of birth!' }]}
                  >
                    <Input placeholder="Place of Birth" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Date of Birth"
                    name="dateOfBirth"
                    rules={[{ required: requied_field, message: 'Please select your date of birth!' }]}
                  >
                    <DatePicker style={{ width: '100%' }} placeholder="Select Date" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[{ required: requied_field, message: 'Please select your gender!' }]}
                  >
                    <Select placeholder="Select Gender">
                      <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

             

              {/* Current Address */}
              <div  className="font-bold text-lg mb-5 text-left">Current Address:</div>


              {/* <h3>Current Address</h3> */}
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    label="Street Number"
                    name="currentStreetNumber"
                  >
                    <Input placeholder="Street Number" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Street Name"
                    name="currentStreetName"
                  >
                    <Input placeholder="Street Name" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Unit Number"
                    name="currentUnitNumber"
                  >
                    <Input placeholder="Unit Number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    label="City"
                    name="currentCity"
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Province"
                    name="currentProvince"
                  >
                    <Input placeholder="Province" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Postal Code"
                    name="currentPostalCode"
                  >
                    <Input placeholder="Postal Code" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Country"
                    name="currentCountry"
                  >
                    <Input placeholder="Country" />
                  </Form.Item>
                </Col>
              </Row>

              
                <div  className="font-bold text-lg mb-5 text-left">Previous Address:</div>

              {addressFields.map((field, index) => (
                <div key={field.key}>
                  <Row gutter={16}>
                    <Col span={6}>
                      <Form.Item
                        label="Street Number"
                        name={`previousStreetNumber${index}`}
                      >
                        <Input placeholder="Street Number" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Street Name"
                        name={`previousStreetName${index}`}
                      >
                        <Input placeholder="Street Name" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Unit Number"
                        name={`previousUnitNumber${index}`}
                      >
                        <Input placeholder="Unit Number" />
                      </Form.Item>
                    </Col>
      
                  </Row>

                  <Row gutter={16}>
                    <Col span={6}>
                      <Form.Item
                        label="City"
                        name={`previousCity${index}`}
                      >
                        <Input placeholder="City" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Province"
                        name={`previousProvince${index}`}
                      >
                        <Input placeholder="Province" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Postal Code"
                        name={`previousPostalCode${index}`}
                      >
                        <Input placeholder="Postal Code" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Country"
                        name={`previousCountry${index}`}
                      >
                        <Input placeholder="Country" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={18}>
                      {/* Remove Button */}
                      {addressFields.length > 1 && (
                        <Button type="danger" onClick={() => removeAddressField(field.key)}>
                          Remove Address
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}

              {/* + Button */}
              <br></br>
              <Form.Item>
                <Button type="dashed" onClick={addAddressField} block>
                  + Add Another Address
                </Button>
                <br></br>
              </Form.Item>
               <div  className="font-bold text-lg mb-5 text-left">Reason for the Criminal Record Verification:</div>
              <Row gutter={16}>
                <Col span={14} >
                  <Form.Item
                    label="Reason for Request (example Employment-Employer-Job Title):"
                    name="reasonOfRequest"
                    rules={[{ required: requied_field, message: 'Please enter Reason for Request' }]}
                  >
                    <Input placeholder="Reason for Request" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Organization Requesting Search"
                    name="organizationRequesting"
                    rules={[{ required: requied_field, message: 'Please enter name of the Organization Requesting Search' }]}
                  >
                    <Input placeholder="Organization Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                
                <Col span={12}>
                  <Form.Item
                    label="Contact Name"
                    name="ozContactName"
                    rules={[{ required: requied_field, message: 'Please enter your Contact name!' }]}
                  >
                    <Input placeholder="Contact Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                <Form.Item
                    label="Contact Phone"
                    name="ozContactPhone"
                    rules={[{ required: requied_field, message: 'Please enter your Contact Phone!' }]}
                  >
                    <Input placeholder="Contact Phone" />
                  </Form.Item>
                </Col>
              </Row>


              <div  className="font-bold text-lg mb-5 text-left">Please Fill if You Have Criminal Record:</div>

              
              <Row gutter={16}>
                <Col span={12} >
                <p class="mb-3 text-left">
                Information is collected and disclosed in accordance with federal, provincial and municipal laws.
                  </p>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col>
                  <Form.Item
                    label="Surname (Last Name)"
                    name="surname"
                    rules={[{ required: requied_field, message: 'Please enter your Surname (Last Name)!' }]}
                  >
                    <Input placeholder="surname" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Given Name(s)"
                    name="givenName"
                    rules={[{ required: requied_field, message: 'Please enter your Given name!' }]}
                  >
                    <Input placeholder="Given Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                
                <Col span={12}>
              <Form.Item
                    label="Date of Birth"
                    name="dateOfBirth"
                    rules={[{ required: requied_field, message: 'Please select your date of birth!' }]}
                  >
                    <DatePicker style={{ width: '100%' }} placeholder="Select Date" />
                  </Form.Item>
                  </Col>
                  </Row>

                  <Row gutter={16}>
                <Col>
                <p class="mb-3 text-left">
                A Declaration of Criminal Record does not constitute a Certified Criminal Record by the RCMP and may not contain all criminal record convictions.
                  </p>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col>
                <p class="mb-3 text-left">
                Applicants must declare all convictions for offences under Canadian federal law.                </p>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col>
                <p class="mb-3 text-left">
                Do not declare the following:<br></br>-A conviction for which you have received a Record Suspension (formerly pardon) in accordance with the Criminal Records Act;<br></br>-A conviction where you were a “young person” under the Youth Criminal Justice Act;<br></br>An Absolute or Conditional Discharge, pursuant to section 730 of the Criminal Code;<br></br>-An offence for which you were not convicted;<br></br>Any provincial or municipal offence, and;<br></br>-Any charged dealt with outside of Canada.
                </p></Col>
              </Row>

{/* Current Address */}
<div  className="font-bold text-lg mb-5 text-left">Add Offence Details:</div>

{offenceFields.map((field, index) => (
  <div key={field.key}>
    <Row gutter={16}>

      
      <Col span={6}>
        <Form.Item
          label="Offence"
          name={`offenece${index}`}
        >
          <Input placeholder="Offence" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Date of Sentence"
          name={`dateOfSentence${index}`}
        >
          <Input placeholder="Date of Sentence" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Court Location"
          name={`courtLocation${index}`}
        >
          <Input placeholder="Court Location" />
        </Form.Item>
      </Col>

    </Row>


    <Row gutter={16}>
      <Col span={18}>
        {/* Remove Button */}
        {offenceFields.length > 1 && (
          <Button type="danger" onClick={() => removeOffenceField(field.key)}>
            Remove Offence
          </Button>
        )}
      </Col>
    </Row>
  </div>
))}

{/* + Button */}
<br></br>
<Form.Item>
  <Button type="dashed" onClick={addOffenceField} block>
    + Add Another Offence
  </Button>
  <br></br>
</Form.Item>
<div  className="font-bold text-lg mb-5 text-left">Offence Details Section Ends Here-</div>
              
              {/* Upload Items */}
              {/* <div  className="font-bold text-lg mb-5 text-left">Upload Documents:</div>


              <Row gutter={16} className='no-pdf'>
                <Col span={8}>
                  <Form.Item
                    label="Front of ID"
                    name="frontOfId"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => e && e.fileList}
                    rules={[{ required: requied_field, message: 'Please upload the front of your ID!' }]}
                  >
                    <Upload {...uploadProps}>
                      <Button icon={<UploadOutlined />}>Upload Front of ID</Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Back of ID"
                    name="backOfId"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => e && e.fileList}
                    rules={[{ required: requied_field, message: 'Please upload the back of your ID!' }]}
                  >
                    <Upload {...uploadProps}>
                      <Button icon={<UploadOutlined />}>Upload Back of ID</Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Your Photo with ID"
                    name="photoWithId"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => e && e.fileList}
                    rules={[{ required: requied_field, message: 'Please upload your photo with ID!' }]}
                  >
                    <Upload {...uploadProps}>
                      <Button icon={<UploadOutlined />}>Upload Your Photo with ID</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
               */}

              <div  className="font-bold text-lg mb-5 text-left">Informed Consent:</div>


              {/* Consent Text */}
              <Form.Item
                name="searchAuthorization"
                valuePropName="checked"
                rules={[{ required: requied_field, message: 'You must agree to the Search Authorization.' }]}
              >
                <Checkbox>
                <p class="mb-3 text-left">
                  Search Authorization: I HEREBY CONSENT TO THE SEARCH OF THE RCMP National
                  Repository of Criminal Records based on the name(s), date of birth, and where used,
                  the declared criminal record history provided by myself. I understand that this
                  verification of the National Repository of Criminal Records is not being confirmed
                  by fingerprint comparison which is the only true means by which to confirm if a
                  criminal record exists in the National Repository of Criminal Records.
                </p>
                  
                </Checkbox>
              </Form.Item>

              {/* Police Information Systems Checkbox */}
              <Form.Item
                name="policeInformationSystems"
                valuePropName="checked"
                rules={[{ required: requied_field, message: 'You must consent to at least one Police Information System.' }]}
              >
                <Checkbox>
                <p class="mb-3 text-left">
                POLICE INFORMATION SYSTEM(S) – I HEREBY CONSENT TO THE SEARCH OF police
                  information systems, as a part of a Police Information Check, which will consist of a search of
                  the following systems (check applicable):
                  </p>
                  
                </Checkbox>
              </Form.Item>

              {/* Police Information Systems Checkboxes */}
              <Form.Item  name="policeSystems" label="Police Information Systems"
                rules={[{ required: requied_field, message: 'Please select at least one system.' }]}>
                <Checkbox.Group>
                  <Checkbox name="cipInvs" value="CPIC Investigative Data Bank">
                    CPIC Investigative Data Bank
                  </Checkbox>
                  <Checkbox name='policeInfoProtal' value="Police Information Portal (PIP)">
                    Police Information Portal (PIP)
                  </Checkbox>
                  <Checkbox name='otherCheckPolice' value="Other">OTHER</Checkbox>
                </Checkbox.Group>
              </Form.Item>

              {/* Authorization and Waiver Text */}

              <Form.Item
                name="anw"
                valuePropName="checked"
                rules={[{ required: requied_field, message: 'You must agree to the Search Authorization.' }]}
              >
                <Checkbox>
                  <p class="mb-3 text-left" >AUTHORIZATION AND WAIVER to provide confirmation of criminal record or any police
                  information. I certify that the information set out by me in this application is true and correct
                  to the best of my ability. I consent to the release of the results of the criminal record checks to
                  METAFORCE CAREERS INC., located in MISSISSAUGA, CANADA.</p>
                  
                </Checkbox>
              </Form.Item>

              {/* Police Information Systems Checkbox */}
              <Form.Item
                name="metaforceConsentSystems"
                valuePropName="checked"
                rules={[{ required: requied_field, message: 'You must consent to at least one Police Information System.' }]}
              >
                <Checkbox>
                  <p class="mb-3 text-left">
                  I hereby release and forever discharge all members and employees of the processing Police
                  Service and the Royal Canadian Mounted Police from any and all actions, claims, and demands
                  for damages, loss, or injury howsoever arising which may hereafter be sustained by myself as
                  a result of the disclosure of information by the COBOURG POLICE SERVICE to METAFORCE
                  CAREERS INC, MISSISSAUGA, CANADA.
                  </p>
                  
                </Checkbox>
              </Form.Item>

              <Col span={12}>
                <Form.Item
                  label="Third Party Organization Name if required"
                  name="thirdPartyName"
                  rules={[{ required: false, message: 'Please enter name of the third party' }]}
                >
                  <Input placeholder="Organization Name" />
                </Form.Item>
              </Col>

              {/* Signature and Consent */}
              <Row gutter={16}>

              <Row>
              <Col span={24}>
                <h3>Signature of Applicant</h3>
                <SignatureCanvas
                  ref={signatureRef}
                  required={requied_field}
                  canvasProps={{width: 500, height: 200, className: 'signature-canvas' , style: { backgroundColor: '#f0f0f0' } // Light grey background
                }}
                />
                <br></br>
                <Button onClick={clearSignature}>Clear</Button>
                <Button onClick={saveSignature}>Save Signature</Button>
              </Col>
            </Row>
                <Col gutter={8}>
                  <Form.Item
                    name="placeofSignature"
                    label="Signed At"
                    rules={[{ required: requied_field, message: 'Please provide city of signing' }]}
                  >
                    <Input placeholder="Enter the city of signing" />
                  </Form.Item>

                  <Form.Item
                    name="dateSigned"
                    label="Date Signed"
                    rules={[{ required: requied_field, message: 'Please provide the date.' }]}
                  >
                    <Input type="date" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                
                <Col> <p class="mb-3 text-left font-bold">
                Name and Location of the Company where information will be stored in Canada: Metaforce Careers Inc. Mississauga, Canada.</p>
                  <p class="mb-3 text-left font-bold">
                  Information related to this criminal record check is collected, retained and disclosed in accordance with applicable privacy legislation.                  </p>
                  </Col>

              </Row>
              <Row>
                <Col>
                <Form.Item>
                <Button type="primary" htmlType="submit" className='no-pdf'>
                  Submit
                </Button>
              </Form.Item>
                </Col>
              

              </Row>
              
            </Form>

          </div>

          {/* ====== Contact Section End */}
        </div>

      </div>
    </div>
  )
}

export default BackgroundCheck;