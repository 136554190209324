/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef } from 'react';
import mainImage from '../../OIFendImages/images/about.svg';
import ourMission from '../../OIFendImages/images/about/main banner/svg/our mission.svg';
import ourVision from '../../OIFendImages/images/about/main banner/svg/our vision.svg';


function About(props) {
  const ref = useRef();
   const { handleChange } = props;
    useEffect(() => {
      ref.current.focus();
        handleChange(false)
    }, []);

    return (
        <div ref={ref}>
         <div>
          <img className="h200" src={mainImage} />
          {/* Features */}
          <section className="px-6 p-10">
            <div className="container mx-auto  flex items-center flex-wrap">
              <div className="w-full md:w-1/2 pr-0 lg:pr-10 text-left">
              <h4 className="text-3xl text-gray-800 font-bold mb-3"><span className="border-b-2 border-blue-600">OUR MISSION</span></h4>
                <p className="text-gray-600 mb-8 text-xl">With high-performing technology, we will give optimal solutions with high-quality and secure services to our valued clients.</p>
                <p className="text-gray-600 mb-8 text-xl">
                  The hiring process is monotonous and costly, particularly when you're seeking out for candidates with specific qualifications and aptitudes.
                  <br/><br/>
                  Outsourcing your staffing needs will spare your time, cash and stretch of overseeing the enlistment process. Metaforce Careers offers entirely adaptable staffing arrangements.
                </p>
              </div>
              <div className="w-full md:w-1/2 mb-8 lg:mb-0">
                <img className="rounded-lg" src={ourMission} alt="Vortex" />
              </div>
            </div>
            <div className="container mx-auto  flex items-center flex-col-reverse lg:flex-row lg:flex-wrap">
              <div className="w-full md:w-1/2  mb-8 lg:mb-0">
                <img className="rounded-lg" src={ourVision} alt="use the force" />
              </div>
              <div className="w-full md:w-1/2 pl-0 lg:pl-10 text-left">
                <h4 className="text-3xl text-gray-800 font-bold mb-3"><span className="border-b-2 border-blue-600">OUR VISION</span></h4>
                <p className="text-gray-600 mb-8 text-xl">To maintain us at the top of the industry leader board by focusing on our customer's success.</p>
                <p className="text-gray-600 mb-8 text-xl">
                  We oversee your transitory laborers so you'll be able to alter your staff as required giving you a competitive advantage and keeping your cost low.
                  <br /><br />
                  All our sales agents and clients care specialists are traded within Metaforce Careers Staffing Quality System and provide our client with a customized staffing arrangement that harmonizes their businesses with the right individuals for the work.
                </p>
              </div>
            </div>

            {/* new sections */}

        </section>
    </div>
  </div>
  )
}

export default About
