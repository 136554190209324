/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import {
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  CommentOutlined
} from '@ant-design/icons';
import './index.css';
import { Drawer, Affix } from 'antd';
import call from '../../OIFendImages/images/Home Page/Icon/svg/call.svg';
import mail from '../../OIFendImages/images/Home Page/Icon/svg/mail.svg';
import menuImage from '../../OIFendImages/images/Home Page/Banner/svg/Menu.svg';
import Gallery from '../Gallerry';
import About from '../About';
import Home from '../Home';
import ContactUs from '../ContactUs';
import BackgroundCheck from '../Bgck';
import Careers from '../Careers';
import Footer from '../Footer';
import logo from '../../../src/Images/logo/metaforce.png'
import { useHistory } from 'react-router-dom';
import { closeIcon } from '../Icons/Icons';



const NavRouter = (props) => {
  const history = useHistory()
  const [visible, setVisible] = useState(false);
  const [pathName, setPathName] = useState();

  console.log("router", history);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(false);
    console.log("route changed", window?.location?.pathname.split('/')[1]);
    // history.listen(location => {
    console.log('route changes', history);
    setPathName(window?.location?.pathname?.split('/')[1])
    // });
  }, [history])

  return (
    <div>
      <div>
        <Router>
          <Drawer
            closable={false}
            title={
              <div className="flex flex-wrap justify-between">
                <div className="text-4xl font-extrabold text-xl flex ">
                  <Link to="/">
                    <img
                      src={logo}
                      className="w-auto" style={{ height: '40px' }} alt="MetaForce" />
                  </Link>
                </div>
                <div className='text-2xl font-extrabold' onClick={()=>setVisible(!visible)}>
                  {closeIcon()}
                </div>
              </div>
            }
            width={350}
            placement="left"
            onClose={onClose}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div>
                <div className="flex py-5 m-auto text-gray-800 text-sm flex-col items-center max-w-screen-xl">
                  <div className="md:flex-auto md:flex-row-reverse mt-2 flex-row flex">
                    <a href="/#" className="w-6 mx-1">
                      <svg className="fill-current cursor-pointer text-gray-500 hover:text-indigo-600" width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}>
                        <path id="Facebook" d="M24,12c0,6.627 -5.373,12 -12,12c-6.627,0 -12,-5.373 -12,-12c0,-6.627
            5.373,-12 12,-12c6.627,0 12,5.373
            12,12Zm-11.278,0l1.294,0l0.172,-1.617l-1.466,0l0.002,-0.808c0,-0.422
            0.04,-0.648 0.646,-0.648l0.809,0l0,-1.616l-1.295,0c-1.555,0 -2.103,0.784
            -2.103,2.102l0,0.97l-0.969,0l0,1.617l0.969,0l0,4.689l1.941,0l0,-4.689Z" />
                      </svg>
                    </a>
                    <a href="/#" className="w-6 mx-1">
                      <svg className="fill-current cursor-pointer text-gray-500 hover:text-indigo-600" width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}>
                        <path id="Shape" d="M7.3,0.9c1.5,-0.6 3.1,-0.9 4.7,-0.9c1.6,0 3.2,0.3 4.7,0.9c1.5,0.6 2.8,1.5
            3.8,2.6c1,1.1 1.9,2.3 2.6,3.8c0.7,1.5 0.9,3 0.9,4.7c0,1.7 -0.3,3.2
            -0.9,4.7c-0.6,1.5 -1.5,2.8 -2.6,3.8c-1.1,1 -2.3,1.9 -3.8,2.6c-1.5,0.7
            -3.1,0.9 -4.7,0.9c-1.6,0 -3.2,-0.3 -4.7,-0.9c-1.5,-0.6 -2.8,-1.5
            -3.8,-2.6c-1,-1.1 -1.9,-2.3 -2.6,-3.8c-0.7,-1.5 -0.9,-3.1 -0.9,-4.7c0,-1.6
            0.3,-3.2 0.9,-4.7c0.6,-1.5 1.5,-2.8 2.6,-3.8c1.1,-1 2.3,-1.9
            3.8,-2.6Zm-0.3,7.1c0.6,0 1.1,-0.2 1.5,-0.5c0.4,-0.3 0.5,-0.8 0.5,-1.3c0,-0.5
            -0.2,-0.9 -0.6,-1.2c-0.4,-0.3 -0.8,-0.5 -1.4,-0.5c-0.6,0 -1.1,0.2
            -1.4,0.5c-0.3,0.3 -0.6,0.7 -0.6,1.2c0,0.5 0.2,0.9 0.5,1.3c0.3,0.4 0.9,0.5
            1.5,0.5Zm1.5,10l0,-8.5l-3,0l0,8.5l3,0Zm11,0l0,-4.5c0,-1.4 -0.3,-2.5
            -0.9,-3.3c-0.6,-0.8 -1.5,-1.2 -2.6,-1.2c-0.6,0 -1.1,0.2 -1.5,0.5c-0.4,0.3
            -0.8,0.8 -0.9,1.3l-0.1,-1.3l-3,0l0.1,2l0,6.5l3,0l0,-4.5c0,-0.6 0.1,-1.1
            0.4,-1.5c0.3,-0.4 0.6,-0.5 1.1,-0.5c0.5,0 0.9,0.2 1.1,0.5c0.2,0.3 0.4,0.8
            0.4,1.5l0,4.5l2.9,0Z" />
                      </svg>
                    </a>
                    <a href="/#" className="w-6 mx-1">
                      <svg className="fill-current cursor-pointer text-gray-500 hover:text-indigo-600" width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}>
                        <path id="Combined-Shape" d="M12,24c6.627,0 12,-5.373 12,-12c0,-6.627 -5.373,-12 -12,-12c-6.627,0
            -12,5.373 -12,12c0,6.627 5.373,12 12,12Zm6.591,-15.556l-0.722,0c-0.189,0
            -0.681,0.208 -0.681,0.385l0,6.422c0,0.178 0.492,0.323
            0.681,0.323l0.722,0l0,1.426l-4.675,0l0,-1.426l0.935,0l0,-6.655l-0.163,0l-2.251,8.081l-1.742,0l-2.222,-8.081l-0.168,0l0,6.655l0.935,0l0,1.426l-3.74,0l0,-1.426l0.519,0c0.203,0
            0.416,-0.145 0.416,-0.323l0,-6.422c0,-0.177 -0.213,-0.385
            -0.416,-0.385l-0.519,0l0,-1.426l4.847,0l1.583,5.704l0.042,0l1.598,-5.704l5.021,0l0,1.426Z" />
                      </svg>
                    </a>
                  </div>
                  <div className="my-5 text-sm text-center">MetaForce Careers © Copyright 2022. All Rights Reserved.</div>
                </div>
              </div>
            }
          >
            <ul className="w-full rounded-lg mt-2 mb-3 text-blue-800">
              <li className="mb-2">
                <a href="#" className="w-fill flex p-3 pl-3 bg-gray-100 hover:bg-gray-200 rounded-lg">
                  <HomeOutlined style={{ fontSize: '20px', paddingTop: '4px' }} />
                  <span className="ml-2 truncate text-lg"><Link to="/">Home</Link></span>
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="w-fill flex p-3 bg-gray-100 hover:bg-gray-200 rounded-lg">

                  <UserOutlined style={{ fontSize: '20px', paddingTop: '4px' }} />
                  <span className="ml-2 truncate text-lg"><Link to="/about">About</Link></span>
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="w-fill flex p-3 bg-gray-100 hover:bg-gray-200 rounded-lg">
                  <SettingOutlined style={{ fontSize: '20px', paddingTop: '4px' }} />
                  <span className="ml-2 truncate text-lg"><Link to="/services">Services</Link></span>
                </a>
              </li>

              <li className="mb-2">
                <a href="#" className="w-fill flex p-3 bg-gray-100 hover:bg-gray-200 rounded-lg">
                  <CommentOutlined style={{ fontSize: '20px', paddingTop: '4px' }} />
                  <span className="ml-2 truncate text-lg"><Link to="/contact">Contact</Link></span>
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="w-fill flex p-3 bg-gray-100 hover:bg-gray-200 rounded-lg">
                  {/* <CommentOutlined style={{ fontSize: '20px', paddingTop: '4px' }} /> */}
                  <span className="ml-2 truncate text-lg"><Link to="/background">Background Check</Link></span>
                </a>
              </li>

            </ul>
          </Drawer>
          <div className="mobileViewShow primaryBGColor" >
            <div className={`primaryBGColor z-50 container mx-auto py-1 w-full bg-white flex justify-between  shadow-lg`} style={{ backgroundColor: '#24357c' }}>
              <div className="flex pl-4 pb-1">
                <img className='h-8 w-8 pt-1' src={call} /> <span className="text-lg font-normal text-white pl-2 pt-1">(+1)-647-248-5430</span>
              </div>
              <div className="flex pr-2 pb-1">
                <img className='h-8 w-8 pt-1' src={mail} /> <span className="text-lg font-normal text-white pl-2 pt-1">info@metaforcecareers.ca</span>
              </div>
            </div>
          </div>
          <Affix offsetTop={0}>
            <nav className="mobileViewShow bg-white pt-2 pb-1 md:pt-4 md:pb-2 shadow-lg">
              <div className="container pl-4 pr-2 mx-auto md:flex md:items-center">
                <div className="flex text-5xl font-bold justify-between items-center">
                  <Link to="/">
                    <img
                      src={logo}
                      className="w-auto" style={{ height: '60px' }} alt="MetaForce" />
                  </Link>
                  <button className="border border-solid border-gray-600 px-3 py-1 rounded text-gray-600 opacity-50 hover:opacity-75 md:hidden" id="navbar-toggle">
                    <i className="fas fa-bars" />
                  </button>
                </div>
                <div className="hidden md:flex flex-col md:flex-row md:ml-auto mt-3 md:mt-0" id="navbar-collapse">
                  <div onClick={() => setPathName('')} className={` font-semobold text-lg text-gray-500 hover:text-gray-900 pl-6 pt-1`}>
                    <Link to="/" style={{ color: pathName === '' ? '#1890ff' : '' }} >Home</Link></div>
                  <div onClick={() => setPathName('about')} className={` font-semobold text-lg text-gray-500 hover:text-gray-900 pl-6 pt-1`}>
                    <Link to="/about" style={{ color: pathName === 'about' ? '#1890ff' : '' }} >About</Link></div>
                  <div onClick={() => setPathName('services')} className={` font-semobold text-lg text-gray-500 hover:text-gray-900 pl-6 pt-1`}>
                    <Link to="/services" style={{ color: pathName === 'services' ? '#1890ff' : '' }} >Services</Link></div>
                  <div onClick={() => setPathName('contact')} className={` font-semobold text-lg text-gray-500 hover:text-gray-900 pl-6 pt-1`}>
                    <Link to="/contact" style={{ color: pathName === 'contact' ? '#1890ff' : '' }} >Contact</Link></div>
                    <div onClick={() => setPathName('background-check')} className={` font-semobold text-lg text-gray-500 hover:text-gray-900 pl-6 pt-1`}>
                    <Link to="/background" style={{ color: pathName === 'background' ? '#1890ff' : '' }} >Background Check</Link></div>
                </div>
                
              </div>
            </nav>

            <nav className={`desktopView  bg-white top-0 py-3 navbar-expand-lg shadow-lg`}>
              <div className={`primaryBGColor z-50 py-2 w-full bg-white   shadow-lg`} style={{ backgroundColor: '#24357c' }}>
                <div className="container mx-auto flex justify-end text-right px-4">
                  <div className="pr-4 flex">
                    <img src={call} className="h-8 w-8" />
                  </div>
                  <div className="pr-2">
                    <img src={mail} className="h-8 w-8" />
                  </div>
                </div>

              </div>
              <div className="container px-4 mx-auto flex justify-between">
                <div className="text-4xl pt-4 font-bold font-extrabold">
                  <Link to="/">
                    <img
                      src={logo}
                      className="w-auto" style={{ height: '40px' }} alt="MetaForce" />
                  </Link>
                </div>
                <div className="cursor-pointer mt-4" ><img src={menuImage} onClick={() => showDrawer()} height={40} style={{ fontSize: '30px' }} /></div>
              </div>
            </nav>
          </Affix>
          <Switch>
            <Route exact={true} path="/" activeClassName="selected">
              <Home handleChange={setVisible} />
            </Route>
            <Route path="/about" activeClassName="selected">
              <About handleChange={setVisible} />
              <Footer />
            </Route>
            <Route path="/services" activeClassName="selected">
              <Gallery handleChange={setVisible} />
              <Footer />
            </Route>
            <Route path="/careers" activeClassName="selected">
              <Careers handleChange={setVisible} />
              <Footer />
            </Route>
            <Route path="/contact" activeClassName="selected">
              <ContactUs handleChange={setVisible} />
              <Footer />
            </Route>
            <Route path="/background" activeClassName="selected">
              <BackgroundCheck handleChange={setVisible} />
              <Footer />
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  )
}

export default NavRouter;
